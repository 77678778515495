* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

a {
  text-decoration: none !important;
}

.btn-primary {
  background-color: #0f2e4f !important;
  border: none;
}

.bg-pri {
  background-color: rgba(53, 47, 41, 0.7);
  /* border: none; */
}

.btn-primary:hover {
  background-color: #6eb5ff !important;
  border: none;
}

#carouselExampleSlidesOnly img {
  height: 85vh;
}

#carouselImg{
  object-fit: contain !important;
  width: 50vw;
  /* height: 100% !important; */
}

.bg-6eb5ff {
  background-color: rgba(110, 181, 255, 0.5);
}

.icon .img-fluid {
  color: #000;
}

.cat-item:hover .img-fluid {
  background-color: #000 !important;
}

.cat-item {
  cursor: pointer;
}

.cat-item span {
  color: #000;
}

.bg-faint {
  background-color: rgba(15, 46, 79, 0.3);
}

.bg-faint img {
  transition: transform 0.5s ease;
}

.bg-faint img:hover {
  transform: scale(1.05);
}

.img-fluid-fixed {
  height: 250px;
  background-color: rgba(53, 47, 41, 0.5);
}

.img-fluid-fixed img {
  height: 100%;
  width: 100%;
}

.text-dark {
  color: rgb(53, 47, 41) !important;
  font-weight: 800;
}

#no-outline {
  outline: none !important;
  border: none !important;
}

#no-outline:focus {
  outline: none !important;
  border: none !important;
  background-color: rgb(110, 181, 255);
}

@keyframes animate-bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-15px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.back-to-top {
  position: fixed;
  bottom: 50px !important;
  right: 30px !important;
  z-index: 100 !important;
}

.back-to-top p {
  animation: animate-bounce 1s infinite;
  font-weight: 900 !important;
  width: 20px !important;
}

.soon-container {
  position: relative;
  height: 100vh;
}

.bg-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../images/banner1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.75; /* Adjust the opacity as needed */
}

.text-container {
  position: relative;
  z-index: 1; /* Ensure text is above the background image */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.soon-text h1,
.soon-text h2 {
  font-size: 4rem;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}
.soon-text h1 {
  color: black;
  animation: fadeInX 2s ease-in-out;
}
.soon-text h2 {
  margin-top: 20%;
  color: #3636a4;
  animation: fadeIns 4s ease-in-out infinite;
}
.get-in-touch p {
  color: #0f2e4f !important;
}
.contact-header,
.about-header,
.property-header {
  background-color: rgba(15, 46, 79, 0.9);
  background-image: url("../images/banner3.avif");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  height: 45vh;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-header {
  background-image: url("../images/banner2.avif");
}

.property-header {
  background-image: url("../images/banner1.jpg");
  min-height: 300px;
}

.contact-header p,
.about-header p {
  color: white;
  font-weight: 900;
  text-align: center;
  background-color: rgba(15, 46, 79, 0.8);
  padding: 10px;
  border-radius: 10px;
  margin: 0px 10px;
}

.about-header p {
  background-color: rgba(128, 77, 0, 0.8);
}

.property-header h1 {
  background-color: rgba(181, 179, 170, 0.6);
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
}

.vision {
  background-color: #f8f9fa; /* Light gray background color */
  padding: 10px 0; /* Add vertical padding for spacing */
}

.vision h2 {
  color: #007bff;
}

.vision p {
  font-size: 18px;
  line-height: 1.6;
}

.hr {
  border-bottom: 10px solid #007bff;
  margin: 10px 0;
  border-radius: 8px;
}

.property-type {
  font-size: 24px;
  margin-bottom: 10px;
}

.property-details,
.key-features,
.photo-gallery,
.location-amenities,
.contact,
.video,
.additional-info {
  margin-bottom: 40px;
}

.details,
.key-features ul,
.photo-gallery,
.location-amenities ul,
.contact,
.additional-info {
  background-color: #f8f9fa;
  padding: 20px;
}

.details h2,
.key-features h2,
.photo-gallery h2,
.location-amenities h2,
.contact h2,
.video h2,
.additional-info h2 {
  color: #0f2e4f;
  margin-bottom: 10px;
}

.photo-gallery {
  display: flex;
  justify-content: space-between;
}

.photo-gallery img {
  width: 45%;
}

.location-amenities {
  position: relative;
}

.location-amenities ul {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.contact,
.video {
  text-align: center;
}

.additional-info {
  font-weight: bold;
}

.details-container {
  display: flex;
  flex-direction: column;
}

.details,
.address {
  margin-bottom: 20px;
}
.details{
  border-radius: 8px;
}

.iconw {
  margin-right: 5px;
}

.finished-images img,
.plan-images img {
  margin: 5px;
  border-radius: 8px;
  width: 20vw;
}

.finished-images img:hover,
.plan-images img:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.faq {
  background-color: white;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 50px;
}

.accordion-item {
  background-color: white;
}

.accordion-header button,
.accordion-header h2 {
  box-shadow: none !important;
  background-color: whitesmoke;
  min-height: 70px;
  height: fit-content;
  font-size: 20px;
  color: #34495e;
  font-weight: 600;
}

.accordion-header button:hover {
  color: #000;
}

.accordion-button:not(.collapsed) {
  color: black;
  background-color: white;
}

.faq h1 {
  color: #34495e;
  font-size: 34px;
}

.accordion-body {
  font-size: 18px;
}
.image-container {
  position: relative;
}

/********** Template CSS **********/
:root {
  --primary: #0f2e4f;
  --secondary: #ff6922;
  --light: #effdf5;
  --dark: #6eb5ff;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 45px;
  bottom: 45px;
  z-index: 99;
}

/*** Spinner ***/
#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-out, visibility 0s linear 0.5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity 0.5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}

/*** Button ***/
.btn {
  transition: 0.5s;
}

.btn.btn-primary,
.btn.btn-secondary {
  color: #ffffff;
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  border-radius: 50px;
}

/*** Navbar ***/
.nav-bar {
  position: relative;
  margin-top: 25px;
  padding: 0 3rem;
  transition: 0.5s;
  z-index: 9999;
}

.nav-bar.sticky-top {
  position: sticky;
  padding: 0;
  z-index: 9999;
}

.navbar {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.08);
}

.navbar .dropdown-toggle::after {
  border: none;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  vertical-align: middle;
  margin-left: 5px;
  transition: 0.5s;
}

.navbar .dropdown-toggle[aria-expanded="true"]::after {
  transform: rotate(-180deg);
}

.navbar-light .navbar-nav .nav-link {
  margin-right: 30px;
  padding: 25px 0;
  color: #ffffff;
  font-size: 15px;
  text-transform: uppercase;
  outline: none;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link.active {
  color: #0a1f4c;
  font-weight: 700;
  border-bottom: 3px solid #0a1f4c;
}

.title {
  height: 50px;
}

.toast-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10500;
  text-transform: uppercase;
  font-size: 8px !important;
}

@media (max-width: 991.98px) {
  .nav-bar {
    margin: 0;
    padding: 0;
  }

  .navbar-light .navbar-nav .nav-link {
    margin-right: 0;
    padding: 10px 0;
  }

  .navbar-light .navbar-nav {
    border-top: 1px solid #eeeeee;
  }
}

.navbar-light .navbar-brand {
  height: 75px;
}

.navbar-light .navbar-nav .nav-link {
  color: var(--dark);
  font-weight: 500;
}

@media (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
    display: block;
    top: 100%;
    margin-top: 0;
    transform: rotateX(-75deg);
    transform-origin: 0% 0%;
    opacity: 0;
    visibility: hidden;
    transition: 0.5s;
  }

  .navbar .nav-item:hover .dropdown-menu {
    transform: rotateX(0deg);
    visibility: visible;
    transition: 0.5s;
    opacity: 1;
  }
}

/*** Header ***/
@media (min-width: 992px) {
  .header {
    margin-top: -120px;
  }
}

.header-carousel .owl-nav {
  position: absolute;
  top: 50%;
  left: -25px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
}

.header-carousel .owl-nav .owl-prev,
.header-carousel .owl-nav .owl-next {
  margin: 7px 0;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: var(--primary);
  border-radius: 40px;
  font-size: 20px;
  transition: 0.5s;
}

.header-carousel .owl-nav .owl-prev:hover,
.header-carousel .owl-nav .owl-next:hover {
  background: var(--dark);
}

@media (max-width: 768px) {
  .header-carousel .owl-nav {
    left: 25px;
  }
}

.breadcrumb-item + .breadcrumb-item::before {
  color: #dddddd;
}

/*** Icon ***/
.icon {
  padding: 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #ffffff !important;
  border-radius: 50px;
  border: 1px dashed var(--primary) !important;
}

/*** About ***/
.about-img img {
  position: relative;
  z-index: 2;
}

.about-img::before {
  position: absolute;
  content: "";
  top: 0;
  left: -50%;
  width: 100%;
  height: 100%;
  background: var(--primary);
  transform: skew(20deg);
  z-index: 1;
}

/*** Category ***/
.cat-item div {
  background: #ffffff;
  border: 1px dashed rgba(0, 185, 142, 0.3);
  transition: 0.5s;
}

.cat-item:hover div {
  background: var(--primary);
  border-color: transparent;
}

.cat-item div * {
  transition: 0.5s;
}

.cat-item:hover div * {
  color: #ffffff !important;
}

/*** Property List ***/
.nav-pills .nav-item .btn {
  color: var(--dark);
}

.nav-pills .nav-item .btn:hover,
.nav-pills .nav-item .btn.active {
  color: #ffffff;
}

.property-item {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.08);
}

.property-item img {
  transition: 0.5s;
}

.property-item:hover img {
  transform: scale(1.1);
}

.property-item .border-top {
  border-top: 1px dashed rgba(53, 47, 41, 0.3) !important;
}

.property-item .border-end {
  border-right: 1px dashed rgba(53, 47, 41, 0.3) !important;
}

/*** Team ***/
.team-item {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.08);
  transition: 0.5s;
}

.team-item .position-relative {
display: flex;
justify-content: center;

}

.team-item .btn {
  color: var(--primary);
  background: #ffffff;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
}

.team-item .btn:hover {
  color: #ffffff;
  background: var(--primary);
}

.team-item:hover {
  border-color: var(--secondary) !important;
}

.team-item:hover .bg-primary {
  background: var(--secondary) !important;
}

.team-item:hover .bg-primary i {
  color: var(--secondary) !important;
}

.team-item img {
    /* width: 100%; */
    /* max-width: 100px; */
    height: 250px;
    /* margin: 10px; */
    margin:  0 auto;
  }

/*** Testimonial ***/
.testimonial-carousel {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

@media (min-width: 576px) {
  .testimonial-carousel {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

.testimonial-carousel .testimonial-item .border {
  border: 1px dashed rgba(0, 185, 142, 0.3) !important;
}

.testimonial-carousel .owl-nav {
  position: absolute;
  width: 100%;
  height: 40px;
  top: calc(50% - 20px);
  left: 0;
  display: flex;
  justify-content: space-between;
  z-index: 1;
}

.testimonial-carousel .owl-nav .owl-prev,
.testimonial-carousel .owl-nav .owl-next {
  position: relative;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: var(--primary);
  border-radius: 40px;
  font-size: 20px;
  transition: 0.5s;
}

.testimonial-carousel .owl-nav .owl-prev:hover,
.testimonial-carousel .owl-nav .owl-next:hover {
  background: var(--dark);
}

/*** Footer ***/
.footer .btn.btn-social {
  margin-right: 5px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0e2e50;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 35px;
  transition: 0.3s;
  background-color: white;
}

.footer .btn.btn-social:hover {
  /* color: var(--light); */
  border-color: #0e2e50 !important;
  background-color: #0e2e50 !important;
  color: white !important;
}

.footer .btn.btn-link {
  display: block;
  margin-bottom: 5px;
  padding: 0;
  text-align: left;
  font-size: 15px;
  font-weight: normal;
  text-transform: capitalize;
  transition: 0.3s;
  color: #0f2e4f;
}

.footer .btn.btn-link::before {
  position: relative;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 10px;
}

.footer .btn.btn-link:hover {
  letter-spacing: 1px;
  box-shadow: none;
}

.footer .form-control {
  border-color: rgba(255, 255, 255, 0.5);
}

.footer .copyright {
  padding: 25px 0;
  font-size: 15px;
  border-top: 1px solid rgba(256, 256, 256, 0.1);
}

.footer .copyright a {
  color: var(--dark);
}

.footer .footer-menu a {
  margin-right: 15px !important;
  padding-right: 15px !important;
  border-right: 1px solid rgba(4, 4, 4, 0.1) !important;
}

.footer .footer-menu a:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: none !important;
}

@keyframes fadeIns {
  0% {
    opacity: 0;
    transform: translateY(-20px) scale(1.05);
  }
  50% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(20px) scale(1.02);
  }
}

@keyframes fadeInX {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
@media (max-width: 768px) {
  .soon-text h1,
  .soon-text h2 {
    font-size: 2rem;
  }
  .about-header p {
    font-size: 14px;
  }

  .team-item {
    display: flex;
    align-items: center;
    text-align: center;
  }

  .team-item img {
    width: 100%;
    max-width: 100px;
    height: auto;
    margin: 10px;
  }

  .photo-gallery {
    flex-direction: column;
  }
  .finished-images,
  .plan-images {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .finished-images img,
  .plan-images img {
    margin: 5px;
    object-fit: contain;
    width: 100%;
  }

  .video {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; /* 16:9 aspect ratio (9 / 16 * 100) */
  }

  .video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
@media (max-width: 425px) {
  .faq {
    margin-top: 5%;
    padding: 10px 5px;
  }

  .faq h1 {
    font-size: 26px;
    text-align: center;
  }

  .accordion-header button {
    font-size: 16px;
  }

  .faq h1 {
    font-size: 20px;
    margin-top: 20px;
  }

  .accordion-body {
    font-size: 14px;
  }

  .title {
    height: auto;
  }

  #carouselImg{
    object-fit: fill !important;
    width: 100vw;
  }

  .location-amenities ul {
    top: 40px;
  }

  .location-amenities h2 {
   margin-bottom: 70px;
  }

  #carouselExampleSlidesOnly img {
    height: 50vh;
  }
}
